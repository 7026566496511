import { Container, Box } from "@mui/material";

export const SuccessPage = () => {
  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          bgcolor: "#6BE388",
          height: "20vh",
          weight: "20vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        Form Successfully Submitted
      </Box>
    </Container>
  );
};
