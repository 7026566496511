import { TextField } from "@mui/material";

interface inputProps {
  label: string;
  value: string;
  placeholder: string;
  required: boolean;
  disabled: boolean;
  helperText: string;
  type: string;
  handleChange: any;
}

const InputField = ({
  label,
  value,
  placeholder,
  required,
  disabled,
  helperText,
  type,
  handleChange,
}: inputProps) => {
  return (
    <>
      <TextField
        label={label}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        id="outlined-required"
        color="secondary"
        type={type}
        fullWidth
        placeholder={placeholder}
        value={value}
        helperText={helperText}
      />
    </>
  );
};

export default InputField;
