/* eslint-disable no-useless-escape */
import { useRef } from "react";
import InputField from "./InputField";
import LocationDropDown from "./LocationDropDown";
import TypeDropDown from "./TypeDropDown";
import JobTitlesDropDown from "./JobTitlesDropDown";
import TransitionsModal from "./TransitionsModal";
import { locationDetails } from "../data/locationDetails";
import { SuccessPage } from "./SuccessPage";

import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as Yup from "yup";
import CssBaseline from "@mui/material/CssBaseline";
import { SetStateAction, useState, useEffect } from "react";
import { PatternFormat } from "react-number-format";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const Form = () => {
  const captchaRef: any = useRef(null);

  const [notSubmitted, setNotSubmitted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [type, setType] = useState("");
  const [serviceBack, setServiceBack] = useState(false);
  const [website, setWebsite] = useState("");

  useEffect(() => {
    setAddress(locationDetails[location]?.address);
    setCity(locationDetails[location]?.city);
    setState(locationDetails[location]?.state);
    setZip(locationDetails[location]?.zip);
    setOfficeNumber(locationDetails[location]?.officeNumber);
    setWebsite(locationDetails[location]?.website);
  }, [location]);

  const handleTitleChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setTitle(e.target.value);
    if (
      title !== "General Manager" &&
      title !== "General Sales Manager" &&
      title !== "Sales Consultant" &&
      title !== "Sales & Leasing Consultant" &&
      title !== "Commercial Sales Manager" &&
      title !== "Commercial Sales Assistant Manager" &&
      title !== "Commercial Sales Consultant" &&
      title !== "Sales & Marketing Consultant"
    ) {
      setType("");
      console.log(type);
    }
  };

  const handleLocationChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setLocation(e.target.value);
  };

  const handleTypeChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setType(e.target.value);
  };

  const handleServiceBackChange = (e: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setServiceBack(!serviceBack);
  };

  // Email regex to pass wikipedia allowed email addresses
  const emailRegex = /^[a-zA-Z0-9 +_.,"\\()<>\/:;!%\-[\]]+$/;

  // Name regexes
  const namesRegex = /^[A-Z][a-zA-Z\-'.]*(?: [A-Z][a-zA-Z\-'.]*)*$/;
  const namesRegex2 = /[a-z]/;

  // Phone regex
  const phoneRegExp = /^[0-9]{1,5}$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(100, "Name cannot be longer than 100 characters")
      .matches(
        namesRegex,
        "The first letter of your first and last name must be capitalized"
      )
      .matches(namesRegex2, "Name must include lowercase letters")
      .required("First and last name is required"),
    email: Yup.string()
      .max(64, "Email cannot be longer than 64 characters")
      .matches(emailRegex, "Is not in correct format")
      .required("Email is required"),
    other: Yup.string().max(
      100,
      "Other job title cannot be longer than 100 characters"
    ),
    extension: Yup.string().matches(
      phoneRegExp,
      "Extension can only contain numbers and be no longer than 5 characters"
    ),
    additionalNotes: Yup.string().max(
      50,
      "Additional notes cannot be longer than 250 characters"
    ),
  });

  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      other: "",
      extension: "",
      additionalNotes: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (e: { [x: string]: any }) => {
      if (
        type === "C" &&
        title !== "General Manager" &&
        title !== "General Sales Manager" &&
        title !== "Sales Consultant" &&
        title !== "Sales & Leasing Consultant" &&
        title !== "Commercial Sales Manager" &&
        title !== "Commercial Sales Assistant Manager" &&
        title !== "Commercial Sales Consultant" &&
        title !== "Sales & Marketing Consultant"
      ) {
        alert("Please select a Type");
      } else {
        setLoading(true);

        const token = captchaRef.current?.getValue();
        captchaRef.current?.reset();

        if (!token) {
          alert("Please complete the reCAPTCHA");
          setLoading(false);
        } else {
          try {
            const response = await fetch(
              isLocalhost
                ? "http://127.0.0.1:5001/business-card-handler/us-central1/outboundPostmark"
                : "https://us-central1-business-card-handler.cloudfunctions.net/outboundPostmark",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  name: formik.values.name,
                  email:
                    formik.values.email.toLowerCase() + "@prestonmotor.com",
                  title: title,
                  other: formik.values.other,
                  location: location,
                  address: address,
                  city: city,
                  state: state,
                  zip: zip,
                  officeNumber: officeNumber,
                  extension: formik.values.extension,
                  cellNumber: cellNumber,
                  type: type,
                  serviceBack: serviceBack,
                  additionalNotes: formik.values.additionalNotes,
                  website: website,
                  token: token,
                }),
              }
            );

            if (response.status === 200) {
              console.log(response);
              setLoading(false);
              setNotSubmitted(false);
            }
          } catch (err: any) {
            console.log(err);
            setLoading(false);
            alert("Form failed to submit. Try again later");
            if (err.response) {
              console.log(err.response.data);
              console.log(err.response.status);
            } else {
              console.log(`Error: ${err.message}`);
            }
          }
        }
      }
    },
  });

  return (
    <div>
      <CssBaseline />

      <Typography
        align="center"
        variant="h4"
        sx={{ mt: 16, mb: 6, fontFamily: "lato", fontWeight: "600" }}
      >
        Business Card Request Form
      </Typography>

      {notSubmitted ? (
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ width: 3 / 4, mx: "auto", maxWidth: 900, mb: 10 }}>
            <Grid
              container
              spacing={2}
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
            >
              {/* Name Input Field */}
              <Grid item sm={12} md={6}>
                <TextField
                  label="First and Last Name"
                  onChange={formik.handleChange}
                  required
                  id="name"
                  name="name"
                  color="secondary"
                  type="text"
                  fullWidth
                  placeholder="John Doe"
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched.name ? (
                      <div>{formik.errors.name as string}</div>
                    ) : null
                  }
                />
              </Grid>

              {/* Email Input Field */}
              <Grid item sm={12} md={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <TextField
                    sx={{ width: "100%" }}
                    onChange={formik.handleChange}
                    required
                    id="email"
                    name="email"
                    color="secondary"
                    type="text"
                    fullWidth
                    placeholder="Email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={
                      formik.touched.email ? (
                        <div>{formik.errors.email as string}</div>
                      ) : null
                    }
                  />

                  <Box
                    sx={
                      formik.touched.email && Boolean(formik.errors.email)
                        ? { pb: "25px" }
                        : { pb: "0px" }
                    }
                  >
                    @prestonmotor.com
                  </Box>
                </Stack>
              </Grid>

              {/* Job Titles Dropdown */}
              <Grid item xs={12} sm={6}>
                <JobTitlesDropDown handleChange={handleTitleChange} />
              </Grid>

              {/* Other Job Input Field */}
              {title === "Other" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Other"
                    onChange={formik.handleChange}
                    required
                    id="other"
                    name="other"
                    color="secondary"
                    type="text"
                    fullWidth
                    placeholder="Other"
                    value={formik.values.other}
                    onBlur={formik.handleBlur}
                    error={formik.touched.other && Boolean(formik.errors.other)}
                    helperText={
                      formik.touched.other ? (
                        <div>{formik.errors.other as string}</div>
                      ) : null
                    }
                  />
                </Grid>
              )}

              {/* Locations Dropdown */}
              <Grid item xs={12} sm={6}>
                <LocationDropDown
                  handleChange={handleLocationChange}
                  location={location}
                />
              </Grid>

              {/* Extra space if other field is chosen in job dropdown */}
              {title === "Other" && (
                <Grid item xs={12} sm={6}>
                  <TextField sx={{ display: "none" }} />
                </Grid>
              )}

              {/* Address Input Field */}
              <Grid item xs={12} sm={4}>
                <InputField
                  label="Address"
                  value={address}
                  placeholder="Address"
                  required={true}
                  disabled={true}
                  helperText=""
                  type="text"
                  handleChange={() => null}
                />
              </Grid>

              {/* City Input Field */}
              <Grid item xs={12} sm={4}>
                <InputField
                  label="City"
                  value={city}
                  placeholder="City"
                  required={true}
                  disabled={true}
                  helperText=""
                  type="text"
                  handleChange={() => null}
                />
              </Grid>

              {/* State Input Field */}
              <Grid item xs={12} sm={4}>
                <InputField
                  label="State"
                  value={state}
                  placeholder="State"
                  required={true}
                  disabled={true}
                  helperText=""
                  type="text"
                  handleChange={() => null}
                />
              </Grid>

              {/* Zipcode Input Field */}
              <Grid item xs={12} sm={4}>
                <InputField
                  label="Zip"
                  value={zip}
                  placeholder="Zip"
                  required={true}
                  disabled={true}
                  helperText=""
                  type="text"
                  handleChange={() => null}
                />
              </Grid>

              {/* Office Number Input Field */}
              <Grid item xs={12} sm={4}>
                <PatternFormat
                  customInput={TextField}
                  fullWidth
                  required
                  label="Office Number"
                  disabled
                  format="(###) ###-####"
                  mask=""
                  placeholder="Office Number"
                  value={officeNumber}
                />
              </Grid>

              {/* Extension Input Field */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Extension"
                  onChange={formik.handleChange}
                  id="extension"
                  name="extension"
                  color="secondary"
                  type="text"
                  fullWidth
                  placeholder="Extension"
                  value={formik.values.extension}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.extension && Boolean(formik.errors.extension)
                  }
                  helperText={
                    formik.touched.extension ? (
                      <div>{formik.errors.extension as string}</div>
                    ) : null
                  }
                />
              </Grid>

              {/* Business Card Type Dropdown */}
              <Grid item xs={12} sm={4}>
                <TypeDropDown
                  handleChange={handleTypeChange}
                  title={title}
                  cardType={type}
                  location={location}
                />
              </Grid>

              {/* Modal displaying Examples of each type */}
              <Grid item xs={12} sm={4}>
                <TransitionsModal />
              </Grid>

              {/* Cell Phone Number Input Field */}
              <Grid item xs={12} sm={4}>
                <PatternFormat
                  customInput={TextField}
                  fullWidth
                  label="Cell Number"
                  format="###-###-####"
                  mask=""
                  placeholder="Cell Number"
                  onChange={(e) => setCellNumber(e.target.value)}
                  error={Boolean(
                    cellNumber.length < 12 && cellNumber.length > 1
                  )}
                  helperText={
                    cellNumber.length < 12 && cellNumber.length > 1 ? (
                      <div>{"Cell phone number must be 10 digits"}</div>
                    ) : null
                  }
                  value={cellNumber}
                />
              </Grid>

              {/* Info Alert for Jobs that require a headshot */}
              {(title === "General Manager" ||
                title === "General Sales Manager" ||
                title === "Sales Consultant" ||
                title === "Sales & Leasing Consultant" ||
                title === "Commercial Sales Manager" ||
                title === "Commercial Sales Assistant Manager" ||
                title === "Commercial Sales Consultant" ||
                title === "Sales & Marketing Consultant") && (
                <Alert sx={{ mt: 2, ml: 2 }} severity="info">
                  Send headshot to Gretta at{" "}
                  <Link href="#">bizcards@prestonmotor.com</Link>
                </Alert>
              )}

              {/* Service Back Checkbox Field */}
              {(title === "Service Advisor" || title === "Service Manager") && (
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={handleServiceBackChange} />}
                      label="Service Back"
                    />
                  </FormGroup>
                </Grid>
              )}

              {/* Additional Notes Text Box */}
              <Grid item xs={12} md={12}>
                <TextareaAutosize
                  className="textbox"
                  aria-label="minimum height"
                  minRows={6}
                  name="additionalNotes"
                  placeholder="Additional Notes"
                  onChange={formik.handleChange}
                  value={formik.values.additionalNotes}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              {/* Warning Text to Double Check Responses */}
              <Grid item xs={12} md={12}>
                <Typography
                  align="center"
                  variant="h6"
                  sx={{
                    fontSize: "17px",
                    fontFamily: "lato",
                    fontWeight: "300",
                  }}
                >
                  Confirm your responses before submitting
                </Typography>
              </Grid>

              {/* Recaptcha Component */}
              <Grid
                item
                xs={12}
                md={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <ReCAPTCHA
                  sitekey="6LdTOsMoAAAAAGtTzJ2DKrJAqQeM0zsVWMazL1Bx"
                  ref={captchaRef}
                />
              </Grid>

              {/* Submit Button */}
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mx: "auto", mt: 2, py: 1, px: 2 }}
                loading={loading}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Box>
        </form>
      ) : (
        <SuccessPage />
      )}
    </div>
  );
};

export default Form;
