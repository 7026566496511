import Header from "./components/Header";
import Form from "./components/Form";
import { Box } from "@mui/material";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
        }}
      >
        <Header />

        <Form />

        <Footer />
      </Box>
    </>
  );
}

export default App;
