import { Box } from "@mui/material";
import frogLogo from "../assets/frog-logo.svg";

const Footer = () => {
  return (
    <div className="footer">
      <a href="https://ifrog.com/" target="_blank" className="link">
        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
          <img src={frogLogo} loading="lazy" className="frog-img" />
        </Box>

        <div className="text">© 2024 iFrog Marketing Solutions</div>
      </a>
    </div>
  );
};

export default Footer;
