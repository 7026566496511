import {
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ReactNode } from "react";

interface titlesProps {
  handleChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
}

const JobTitlesDropDown = ({ handleChange }: titlesProps) => {
  return (
    <>
      <FormControl sx={{ width: 1 }}>
        <InputLabel htmlFor="grouped-select">Job Titles *</InputLabel>
        <Select
          required
          defaultValue=""
          id="grouped-select"
          label="Grouping"
          onChange={handleChange}
        >
          <ListSubheader>Sales</ListSubheader>
          <MenuItem value={"General Sales Manager"}>
            General Sales Manager
          </MenuItem>
          <MenuItem value={"Sales & Leasing Consultant"}>
            Sales & Leasing Consultant
          </MenuItem>
          {/* <MenuItem value={"Sales Consultant"}>Sales Consultant</MenuItem> */}
          {/* <MenuItem value={"Sales & Marketing Consultant"}>
            Sales & Marketing Consultant
          </MenuItem> */}
          <MenuItem value={"Commercial Sales Manager"}>
            Commercial Sales Manager
          </MenuItem>
          <MenuItem value={"Commercial Sales Assistant Manager"}>
            Commercial Sales Assistant Manager
          </MenuItem>
          <MenuItem value={"Commercial Sales Consultant"}>
            Commercial Sales Consultant
          </MenuItem>
          <ListSubheader>Service</ListSubheader>
          <MenuItem value={"Service Manager"}>Service Manager</MenuItem>
          <MenuItem value={"Service Advisor"}>Service Advisor</MenuItem>
          <ListSubheader>Other</ListSubheader>
          <MenuItem value={"General Manager"}>General Manager</MenuItem>
          <MenuItem value={"Parts Manager"}>Parts Manager</MenuItem>
          <MenuItem value={"Body Shop Manager"}>Body Shop Manager</MenuItem>
          <MenuItem value={"Body Shop Assistant Manager"}>
            Body Shop Assistant Manager
          </MenuItem>
          <MenuItem value={"Finance Manager"}>Finance Manager</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default JobTitlesDropDown;
