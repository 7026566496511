import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import typeA from "../assets/Example-OptA.jpg";
import typeB from "../assets/Example-OptB.jpg";
import typeC from "../assets/Example-OptC.jpg";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  minWidth: "450px",
  maxWidth: "1300px",
  maxHeight: "750px",
};

export default function TransitionsModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button sx={{ mt: 1 }} onClick={handleOpen}>
        Card Examples
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              aria-label="delete"
            >
              <CloseIcon onClick={handleClose} />
            </IconButton>

            <Typography id="transition-modal-title" variant="h6" component="h2">
              Type A
            </Typography>
            <img src={typeA} loading="lazy" className="type-img" />

            <Typography id="transition-modal-title" variant="h6" component="h2">
              Type B
            </Typography>
            <img src={typeB} loading="lazy" className="type-img" />

            <Typography id="transition-modal-title" variant="h6" component="h2">
              Type C
            </Typography>
            <img src={typeC} loading="lazy" className="type-img" />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
