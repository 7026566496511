import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ReactNode } from "react";

interface typeProps {
  handleChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  title: string;
  cardType: string;
  location: string;
}


const TypeDropDown = ({ handleChange, title, cardType, location }: typeProps) => {
  return (
    <>
      <FormControl sx={{ width: 1 }}>
        <InputLabel>Card Types *</InputLabel>
        <Select
          required
          value={cardType}
          label="Card Types"
          onChange={handleChange}
        >
          <MenuItem disabled={location === "Preston Powersports" || location === "Preston Lawn & Equipment"} value={"A"}>A</MenuItem>
          <MenuItem disabled={location === "Preston Powersports" || location === "Preston Lawn & Equipment"} value={"B"}>B</MenuItem>
          <MenuItem disabled={location === "Preston Powersports" || location === "Preston Lawn & Equipment"} value={"C"}>C</MenuItem>
          <MenuItem disabled={(location !== "Preston Powersports")} value={"D"}>D</MenuItem>
          <MenuItem disabled={(location !== "Preston Lawn & Equipment")} value={"E"}>E</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default TypeDropDown;
